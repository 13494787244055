<template>
  <div class="result-detail">
    <ori-header />

    <div class="container result-wrap">
      <div class="w1200 top-panel">
        <div class="avatar">
          <div class="img">
            <img src="http://dummyimage.com/80x80/0F52BA" alt="" srcset="" />
          </div>
          <div class="name">Ronald Gonzalez</div>
          <div class="contact-link">
            <span>
              <i class="el-icon-chat-dot-round"></i>
            </span>
            <span>联系TA</span>
          </div>
        </div>

        <div class="info-left">
          <div class="title">求职招聘系统，小程序端</div>
          <div class="type">
            <span>门户网站</span>
          </div>
          <div class="info-row">
            <span>需求编号：</span>
            <span>D210830163039841095102</span>
          </div>
          <div class="info-row">
            <span>终端版本：</span>
            <span>Android</span>
          </div>
          <div class="info-row">
            <span>发布时间：</span>
            <span>2021-08-28 发布</span>
          </div>
          <div class="info-row">
            <span>联系方式：</span>
            <span>177****3884</span>
          </div>
        </div>

        <div class="info-center">
          <div class="info-row price">
            <span>预算：</span>
            <span>¥6,500.00 ~¥7,000.00</span>
          </div>
          <div class="info-row">
            <span>语言需求：</span>
            <span>无需求</span>
          </div>
          <div class="info-row">
            <span>期望完成时间：</span>
            <span>2021-09-03</span>
          </div>
          <div class="info-row">
            <span>是否需要源码：</span>
            <span>不需要</span>
          </div>
          <div class="info-row">
            <span>地域要求：</span>
            <span>无</span>
          </div>
        </div>

        <div class="info-right">
          <div class="top">
            <div class="box">
              <span>
                <i class="el-icon-document"></i>
                申请合作人数
              </span>
              <span> 3 </span>
            </div>
            <div class="box">
              <span>
                <i class="el-icon-view"></i>
                浏览人数
              </span>
              <span> 38 </span>
            </div>
          </div>
          <div class="middle">
            <el-button size="medium" type="primary" style="width: 200px"
              >申请合作</el-button
            >
          </div>
          <div class="bottom">
            <span>当月已申请合作次数：0/10</span>
            <span>
              <i class="el-icon-share"></i>
              分享
            </span>
          </div>
        </div>
      </div>

      <div class="w1200 bottom-panel">
        <div class="left">
          <div class="content-box">
            <div class="title">需求详情</div>
            <div class="desc">
              需求描述：<br />
              【业务需求】<br />
              乡村振兴建设方案：种植养殖业信息采集统计、新零售网络营销、农村物流、新农业（旅游观光、文化、养老等）、乡村特色产品项目等方面建设。<br />
              【人员要求】<br />
              要有同类项目厂家<br />
              【交付要求】<br />
              通过数科邦平台完成交付.<br />
            </div>
          </div>
          <div class="content-box">
            <div class="title">需求文件</div>
            <div class="desc">
              <el-empty description="暂无附件"></el-empty>
            </div>
          </div>
          <div class="apply-btn">
            <el-button style="width: 200px" type="primary" size="medium" plain
              >合作申请</el-button
            >
          </div>
        </div>
        <div class="right">
          <div class="ad-img">
            <img src="http://dummyimage.com/330x150/FF6B6B" alt="" />
          </div>

          <div class="head">热门需求</div>

          <div class="items1">
            <div
              class="box"
              v-for="(i, index) in list"
              :key="i.id"
              v-show="index < 3"
            >
              <div class="title">
                {{ i.title }}
              </div>
              <div class="ly-tag">
                <span>项目领域：</span>
                <span>{{ i.typeTag }}</span>
              </div>
              <div class="price-time">
                <span> ￥{{ i.lowPrice }} - ￥{{ i.heightPrice }} </span>
                <span>
                  <i class="el-icon-time"></i>
                  {{ i.count }} 天前</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <page-foot />
  </div>
</template>

<script>
import { resultData } from "@/Mock";
import PageFoot from "../../../components/ori-com/PageFoot.vue";
export default {
  components: { PageFoot },
  data() {
    return {
      list: resultData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.result-detail {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;

  .container {
    width: 100%;
    overflow: hidden;
  }

  .result-wrap {
    padding-bottom: 30px;
    .top-panel {
      background-color: #fff;
      min-height: 207px;
      margin-top: 27px;
      padding: 18px 35px;
      position: relative;

      display: flex;
      align-items: flex-start;

      .avatar {
        width: 80px;
        .img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 80px;
            height: 80px;
          }
        }

        .name {
          line-height: 22px;
          color: #999;
          text-align: center;
          word-break: break-all;
          font-size: 12px;
        }

        .contact-link {
          cursor: pointer;
          line-height: 22px;
          text-align: center;
          font-size: 12px;
          color: #666;
          i {
            color: #7fc8a9;
            padding-right: 2px;
          }
        }
      }

      .info-left {
        min-width: 300px;
        margin-left: 35px;
        max-width: 340px;
        margin-right: 10px;

        .title {
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 6px;
          line-height: 22px;
          word-break: break-all;
        }

        .type {
          overflow: hidden;
          height: 25px;
          max-width: 320px;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            background-color: #f4f5f9;
            color: #999;
            width: 75px;
            height: 24px;
            line-height: 21px;
            text-align: center;
            display: inline-block;
            border-radius: 3px;
            margin-right: 5px;
            margin-bottom: 5px;
            overflow: hidden;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            padding: 1px;
          }
        }

        .info-row {
          margin-bottom: 6px;
          font-size: 12px;
          color: #666;
          line-height: 22px;
          span:nth-child(1) {
            color: #999;
          }
        }
      }

      .info-center {
        margin-top: 30px;

        .info-row {
          font-size: 12px;
          height: 28px;
          line-height: 28px;
          color: #666;
          span:nth-child(1) {
            color: #999;
          }
          &.price {
            span:nth-child(2) {
              color: #f43337;
              font-size: 20px;
            }
          }
        }
      }

      .info-right {
        margin-top: 37px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .top {
          display: flex;
          .box {
            margin-left: 50px;
            text-align: center;

            i {
              font-size: 16px;
            }

            span {
              display: block;
            }

            span:nth-child(1) {
              font-size: 12px;
              color: #999;
              margin-bottom: 5px;
            }

            span:nth-child(2) {
              color: #666;
              max-width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-align: center;
              text-overflow: ellipsis;
              font-size: 20px;
              font-weight: bold;
            }
          }
        }

        .middle {
          margin-top: 10px;
          margin-bottom: 15px;
        }

        .bottom {
          height: 40px;
          line-height: 40px;
          color: #999;
          font-size: 12px;
          i {
            font-size: 16px;
          }
          span {
            margin-left: 22px;
          }
        }
      }
    }

    .bottom-panel {
      margin-top: 14px;
      display: flex;

      align-items: flex-start;

      .left {
        flex: 1 1 auto;
        background-color: #fff;
        padding: 15px 20px 30px 20px;

        .content-box {
          .title {
            font-size: 16px;
            font-weight: 700;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #ddd;
          }

          .desc {
            margin-top: 22px;
            margin-bottom: 35px;
            white-space: pre-wrap;
            word-wrap: break-word;
            font-size: 12px;
            color: #333;
            line-height: 26px;
          }
        }

        .apply-btn {
          padding: 50px 0;
          text-align: center;
        }
      }

      .right {
        flex: 0 0 auto;
        width: 330px;
        margin-left: 15px;

        .ad-img {
          width: 100%;
          img {
            width: 100%;
          }
        }

        .head {
          height: 46px;
          line-height: 46px;
          background-color: #fff;
          font-size: 16px;
          color: #000;
          font-weight: bold;
          padding-left: 16px;
        }

        .items1,
        .items2 {
          background-color: #fff;
          margin-top: 12px;
          padding: 0 14px;
          overflow: hidden;
          margin-bottom: 20px;
        }

        .items1 {
          .box + .box {
            border-top: 1px dashed #ddd;
          }
          .box {
            padding-top: 15px;
            .title {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .ly-tag {
              color: #666;
              font-size: 12px;
              display: block;
              overflow: hidden;
              height: 18px;
              line-height: 18px;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 10px;
            }

            .price-time {
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              span:nth-child(1) {
                color: #f43337;
                font-size: 14px;
                font-weight: bold;
              }
              span:nth-child(2) {
                font-size: 14px;
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}
</style>